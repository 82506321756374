/* You can add global styles to this file, and also import other style files */
@use '@ngneat/hot-toast/src/styles/styles.scss';
@import 'variables';
@import 'autocomplete';

html,
body {
    min-height: 100vh;
}

// Removes any animations on tlv (transition and animation only if we have to)
.grid-container {

    * {
        transition: none !important;
        animation: none !important;
    }
}

.is-title-clipped {

    // if @mixin() font-size should be passed as arg
    min-height: 5rem;
    max-height: 5rem; // Assume from $body-line-height: 1.5 !default; defined in _generic.scss file
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    // Ensure better compatibility
    overflow-wrap: break-word;
    word-wrap: break-word;
    // Future proof
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

@mixin fai-background {
    background: $white url('/assets/fai/header-bg.jpg') no-repeat top;
    background-size: 130%;
}

input[readonly], select.has-actions-disabled{
    background-color: #f9f9f9;
    border-color: #f9f9f9;
    color: #b3b3b3;
}

// Trilogy 2.8.0 Polyfill

.select select:not([multiple]) {
    padding-right: 2.5em;
}